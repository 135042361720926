<template>
  <div class="main">
    <van-tabs v-model:active="active" class="common-tabs">
      <van-tab title="待提货">
        <div class="table">
          <div class="thead">
            <div class="tr">
              <div class="td">下单日期</div>
              <div class="td">品名</div>
              <div class="td">数量</div>
              <div class="td">购买人</div>
              <div class="td">联系方式</div>
            </div>
          </div>
          <div class="tbody" style="max-height:calc(100vh - 204px);overflow:auto">
            <div class="tr" v-for="item in data1" :key="item.id">
              <div class="td">{{ item.input_time?.substring(5, 10) || '-' }}</div>
              <div class="td">{{ item.goods_name || '-' }}</div>
              <div class="td">{{ item.num || '-' }}</div>
              <div class="td">{{ item.xm || '-' }}</div>
              <div class="td"><a style="font-size:14px;color:#333;" :href="'tel:' + item.sjhm">{{
                item.sjhm || '-'
              }}</a></div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="已提货">
        <div class="table">
          <div class="thead">
            <div class="tr">
              <div class="overlayHead">
                <div class="btn" @click="handlePrevDay()">
                  <van-icon name="arrow-left" />
                </div>
                <div class="dateInner">{{ currentRq }}</div>
                <div class="btn" @click="handleNextDay()">
                  <van-icon name="arrow" />
                </div>
              </div>
            </div>
            <div class="tr">
              <div class="td">提货日期</div>
              <div class="td">品名</div>
              <div class="td">数量</div>
              <div class="td">购买人</div>
              <div class="td">联系方式</div>
            </div>
          </div>
          <div class="tbody" style="max-height:calc(100vh - 204px);overflow:auto">
            <div class="tr" v-for="item in data2" :key="item.taocan_id">
              <div class="td">{{ item.input_time?.substring(5, 10) || '-' }}</div>
              <div class="td">{{ item.goods_name || '-' }}</div>
              <div class="td">{{ item.num || '-' }}</div>
              <div class="td">{{ item.xm || '-' }}</div>
              <div class="td"><a style="font-size:14px;color:#333;" :href="'tel:' + item.sjhm">{{
                item.sjhm || '-'
              }}</a></div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios';
import dayjs from 'dayjs';
import { showConfirmDialog, showNotify, showToast } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      active: 0,
      showShare: false,
      value: "",
      currentTime: '',
      currentRq: '',
      store: null,
      loading: false,
      data1: [],
      data2: [],
    };
  },
  async created() {
    this.currentTime = dayjs();
    this.currentRq = this.currentTime.format('YYYY年MM月')
    await this.getStore();
    this.getData1();
    this.getData2();
  },
  computed: {
    ...mapState([]),
  },
  methods: {
    ...mapActions([]),
    watch: {
      $route(route) {
        this.getData1(route.query.search || '')
        this.getData2(route.query.search || '')
      }
    },
    async handlePrevDay() {
      if (this.loading) return;
      this.loading = true;
      this.currentTime = this.currentTime?.subtract(1, 'month');
      this.currentRq = this.currentTime.format('YYYY年MM月')
      await this.getData2();
      this.loading = false;
    },
    async handleNextDay() {
      if (this.loading) return;
      this.loading = true;
      this.currentTime = this.currentTime?.add(1, 'month');
      this.currentRq = this.currentTime.format('YYYY年MM月');
      await this.getData2();
      this.loading = false;
    },

    async getData1(val = '') {
      let { data: res } = await axios.get("/api/api/get_yxsc_dth", {
        params: {
          store_id: this.store.id,
          name: val
        }
      });
      this.data1 = res.data;
    },
    async getData2(val = '') {
      let { data: res } = await axios.get("/api/api/get_yxsc_yth", {
        params: {
          store_id: this.store.id,
          name: val,
          month: this.currentTime.format('YYYY-MM')
        }
      });
      this.data2 = res.data;
    },

    async getStore() {
      let { data: res } = await axios.get("/api/api/get_guanli_mendian", {
        params: {
          sjhm: this.$route.query.sjhm
        }
      });
      this.store = res.data?.[0] || null;
    },
  },
  components: {}
}
</script>
<style lang="less" scoped>
.main {
  flex: 1;
  overflow: auto;
  width: 100%;
}

.table {
  text-align: center;

  .thead {
    color: #fff;
    font-size: 14px;

    .tr {
      display: flex;
      min-height: 32px;
      align-items: center;
      justify-content: space-between;
      background: #3395a2;

      .overlayHead {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 100%;

        .btn {
          padding: 0 24px;
          height: 24px;
          display: flex;
          align-items: center;
        }
      }

      .td {
        &:nth-child(3) {
          flex: .5;
        }

        &:last-child {
          flex: 1.5;
        }

        flex: 1;
      }
    }
  }

  .tbody {
    color: #333;
    font-size: 14px;

    .tr {
      display: flex;
      min-height: 44px;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e5e6e7;

      .td {
        padding: 6px 0;
        word-break: break-all;
        flex: 1;

        &:nth-child(3) {
          flex: .5;
        }

        &:last-child {
          flex: 1.5;
        }

        &.red {
          color: red;
        }
      }
    }
  }
}
</style>
